
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  onMounted,
} from 'vue';

import PrimeVueToast from 'primevue/toast';

import { useToast } from 'primevue/usetoast';

import type {
  EntriesPaginator,
  Projects,
  Users,
  Creatives,
  Creative,
} from '@/types';

import {
  ENTRIES,
  USER_TYPE_AFFILIATE,
  CREATIVE_APPROVAL_STATUSES,
  CREATIVE_APPROVAL_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    PrimeVueToast,
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
    CreativeApprovalStatus: defineAsyncComponent(() => import('@/components/CreativeApprovalStatus.vue')),
  },
  setup() {
    const toast = useToast();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const creatives = reactive<Creatives>({ ...ENTRIES });

    const creativesFilters = reactive({
      project_id: null,
      user_id: null,
      name: null,
      approval_status: null,
    });

    const fetchCreatives = async (creativesPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/creative/getCreativesPaginator',
        {
          ...creativesPaginator,
          ...creativesFilters,
        },
      );

      Object.assign(creatives, response.data);
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? creativesFilters.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const onProjectChange = (): void => {
      creativesFilters.user_id = null;

      fetchUsersWrapper();
    };

    const previewCreative = (creative: Creative): void => {
      toast.removeAllGroups();

      toast.add({
        severity: 'info',
        styleClass: 'p-toast-push',
        detail: creative,
        group: 'bc',
        life: 5000,
      });
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isAffiliate() ? Promise.resolve() : fetchUsersWrapper(),
      ]);
    });

    return {
      CREATIVE_APPROVAL_STATUSES,
      CREATIVE_APPROVAL_STATUSES_MAP,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      creatives,
      creativesFilters,
      fetchCreatives,
      onProjectChange,
      previewCreative,
    };
  },
});
